.flex-info {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 15px;
    width: 50%;
    margin-bottom: 10px;
}

.flex-info div {
    width: 100%;
}
.flex-info-two {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 15px;
    width: 100%;
    margin-bottom: 2px;
}

.flex-info-two div {
    width: 100%;
}

@media screen and (max-width: 1200px) {
    .flex-info {
        width: 100%;
    }
    .flex-info-two div {
        width: 50%;
    }
}